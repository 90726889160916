var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',{staticClass:"p-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.formEditTitle)+" ")])])],1)],1),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.save()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"type":"text","label":"Nombre","dense":"","outlined":"","rules":_vm.rules.required.concat([
                  _vm.rules.maxLength(_vm.rgVarNombre, 50),
                  _vm.rules.requiredTrim(_vm.rgVarNombre) ])},model:{value:(_vm.rgVarNombre),callback:function ($$v) {_vm.rgVarNombre=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"rgVarNombre"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Descripción de variable comercial","dense":"","outlined":"","rules":_vm.rules.required.concat([
                  _vm.rules.maxLength(_vm.rgVarDescrip, 50),
                  _vm.rules.requiredTrim(_vm.rgVarDescrip) ])},model:{value:(_vm.rgVarDescrip),callback:function ($$v) {_vm.rgVarDescrip=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"rgVarDescrip"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.rgProcCombo,"item-text":"rgProcDescripGral","item-value":"id","label":"Procedimiento","outlined":"","clearable":"","dense":"","disabled":_vm.useInReglaCom,"return-object":"","rules":_vm.rules.required},on:{"input":function($event){return _vm.permitirAcumular(_vm.rgProcSelect)},"change":function($event){_vm.rgProcSelect
                  ? ((_vm.descProcNombre = _vm.rgProcSelect.rgProcDescripGral),
                    _vm.resetParametro())
                  : (_vm.descProcNombre = null)}},model:{value:(_vm.rgProcSelect),callback:function ($$v) {_vm.rgProcSelect=$$v},expression:"rgProcSelect"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-switch',{staticClass:"py-0 my-2",attrs:{"label":"Inactivo","dense":"","outlined":""},model:{value:(_vm.rgVarInactivo),callback:function ($$v) {_vm.rgVarInactivo=$$v},expression:"rgVarInactivo"}})],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-card-actions',{staticClass:"py-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"form":"form","color":"primary","loading":_vm.btnIsLoading}},[_vm._v(" Guardar ")])],1)],1)],1),_c('v-row',[(!_vm.rgVarId)?_c('v-subheader',[_vm._v(" (*)Se debe guardar los datos para poder cargar valores a la variable comercial")]):_vm._e()],1),_c('v-card-title',{staticClass:"p-0"},[_c('v-row',[_c('v-col',{staticClass:"mr-n13",attrs:{"cols":"6"}},[_c('span',{staticClass:"primary--text"},[_vm._v(" Valores ")])]),_c('v-col',{attrs:{"cols":"3"}},[(this.editVariableItem || this.seeBtnHistorial)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.openModalHistorialLog()}}},[_vm._v(" Historial ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"3"}},[(_vm.rgVarId)?_c('v-btn',{attrs:{"loading":_vm.btnIsLoading,"color":"primary"},on:{"click":function($event){return _vm.openMonto()}}},[_vm._v(" Agregar valor ")]):_vm._e()],1)],1)],1),_c('v-card',{staticClass:"mb-4"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"item-key":"rgVarValorId","items":_vm.registroSelected,"loading":_vm.loading,"loading-text":"Cargando datos...","sort-by":['periodoVigenciaHasta'],"sort-desc":true},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.editMonto(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.editIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Editar valor")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.deleteVariable(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.deleteIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar valor")])])]}},{key:"item.importe",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.formattedImporte)+" ")]}}],null,true)})],1),(_vm.openCargaMonto)?_c('v-dialog',{attrs:{"max-width":"40rem","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.cerrarModalMonto($event)}},model:{value:(_vm.openCargaMonto),callback:function ($$v) {_vm.openCargaMonto=$$v},expression:"openCargaMonto"}},[_c('v-card',[_c('v-container',[_c('v-card-title',{staticClass:"p-0"},[_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.titleValor)+" ")])]),_c('v-card',{staticClass:"mx-auto mb-3 pt-0",attrs:{"max-width":"100%"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"label":"Período desde","dense":"","outlined":"","hint":"Formato AAAAMM","onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)","rules":_vm.rules.required.concat(
                          [
                            _vm.rules.validDateRange(
                              _vm.vigenciaDesde,
                              _vm.vigenciaHasta
                            ) ],
                          _vm.rules.periodoYyyyMm
                        )},model:{value:(_vm.vigenciaDesde),callback:function ($$v) {_vm.vigenciaDesde=$$v},expression:"vigenciaDesde"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"label":"Período hasta","dense":"","hint":"Formato AAAAMM","outlined":"","onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)","rules":_vm.rules.required.concat(
                          [
                            _vm.rules.validDateRange(
                              _vm.vigenciaDesde,
                              _vm.vigenciaHasta
                            ) ],
                          _vm.rules.periodoYyyyMm
                        )},model:{value:(_vm.vigenciaHasta),callback:function ($$v) {_vm.vigenciaHasta=$$v},expression:"vigenciaHasta"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"type":"number","label":"Valor","rules":_vm.rgVarValor || _vm.rgVarValor === 0
                          ? _vm.rules.requiredAcceptZeroDecimal.concat(
                              _vm.rules.maxDigitsDecimalNegative(7, 2)
                            )
                          : ['Campo requerido'],"dense":"","outlined":""},model:{value:(_vm.rgVarValor),callback:function ($$v) {_vm.rgVarValor=$$v},expression:"rgVarValor"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.cerrarModalMonto}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"disabled":!_vm.isFormValid,"form":"form","color":"primary","loading":_vm.btnIsLoadingMonto || _vm.saving},on:{"click":function($event){return _vm.guardarMonto()}}},[_vm._v(" Guardar ")])],1)],1)],1)],1)],1):_vm._e()],1)],1),(_vm.openHistorialLogValores)?_c('v-dialog',{attrs:{"maxWidth":'65%',"persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeModalHistorialLog($event)}},model:{value:(_vm.openHistorialLogValores),callback:function ($$v) {_vm.openHistorialLogValores=$$v},expression:"openHistorialLogValores"}},[_c('LogValorVarReglaComercial',{attrs:{"rgVarId":this.rgVarIdToHistorial},on:{"closeModalLog":_vm.closeModalHistorialLog}})],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.set}},[_vm._v(" Cerrar ")])],1),_c('DeleteDialog',{attrs:{"titleProp":_vm.titleDelete,"maxWidth":'35%',"openDelete":_vm.showDeleteModal},on:{"update:openDelete":function($event){_vm.showDeleteModal=$event},"update:open-delete":function($event){_vm.showDeleteModal=$event},"onDeleteItem":function($event){return _vm.confirmDelete()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }